@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

body {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "header"
        "main"
        "footer";
}

header {
    grid-area: header;
}

main {
    grid-area: main;
    padding: 1rem;
}

footer {
    grid-area: footer;
}

h1 {
    max-width: 900px;
    margin: auto;
    color: #c7b198;
}

p {
    max-width: 900px;
    margin: auto;
}

.Errorpage {
    max-width: 900px;
    margin: auto;
}
